import React, { useState } from "react";
import Overlay from "../components/Overlay";
import Container from "../components/Container";

interface UnlockScreenProps {
  onUnlock: (p: string) => boolean;
}

const UnlockScreen: React.FC<UnlockScreenProps> = ({ onUnlock }) => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!onUnlock(password)) {
      setErrorMessage("Incorrect password.");
    }
  };

  return (
    <Overlay>
      <Container justify="center">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS64owuVBXy0npVW8lq7oIiiCku5fq0Ip5xSsK18lQ&s
"
          alt="Logo"
          className="h-20 w-20 mb-8"
        />
        <form onSubmit={handleFormSubmit} className="flex flex-col w-2/3">
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={handlePasswordChange}
            className="appearance-none border rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          />
          {errorMessage && (
            <div className="text-red-500 mb-4">{errorMessage}</div>
          )}
          <button
            type="submit"
            className="bg-teal-500 hover:bg-teal-700 text-white text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
          >
            Unlock
          </button>
        </form>
      </Container>
    </Overlay>
  );
};

export default UnlockScreen;
