import React, { useState, useEffect } from "react";
import Container from "../components/Container";
import Overlay from "../components/Overlay";
import { Word } from "./WordListShow";
import { Account } from "../account/Account";
import { NUMBER_OF_WORDS } from "../account/KeyManagement";

interface RecoveryCardProps {
  children?: JSX.Element | JSX.Element[];
}

interface WordListCreateProps {}

const RecoveryCard: React.FC<RecoveryCardProps> = ({ children }) => {
  return (
    <div className="flex flex-col w-fulloverflow-hidden h-1/2 my-4 w-full">
      <div className="flex flex-col w-full bg-gray-100 rounded-md  p-4 mb-4 h-screen max-h-full overflow-y-auto">
        <h2 className="text-lg font-bold mb-2">Recovery card</h2>
        {children}
      </div>
    </div>
  );
};

export default RecoveryCard;
