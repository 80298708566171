import axios, { AxiosResponse } from "axios";
import { fromBech32, toBech32 } from "../account/Utils";
import BN from "bn.js";

export interface TransactionSummary {
  id: string;
  fromAddress: string;
  toAddress: string;
  timestamp: Date;
  amount: BN;
  fee: BN;
  description?: string;
  direction: "in" | "out";
}

interface Transaction {
  accepted: boolean;
  timestamp: string;
  blockId: string;
  txId: string;
  toAddress: string;
  fromAddress: string;
  tokenAddress: string;
  amount: string;
  cumulativeGas: string;
  gasPrice: string;
}

interface AccountBalance {
  balance: string;
}

interface GetTransactionsParams {
  addr: string;
  page?: number;
  perPage?: number;
}

export class ZilliqaGraphQLApiClient {
  private endpoint: string;

  constructor(endpoint?: string) {
    this.endpoint = endpoint || "https://api.zindex.zilliqa.com/v1/api/";
  }

  async getAccountBalance(addr: string): Promise<{
    blockHeight: number;
    balance: BN;
    nonce: number;
  }> {
    addr = fromBech32(addr);

    const nonce = "0"; // TODO:
    // Implementation using indexer
    const { data } = await axios({
      method: "POST",

      url: `${this.endpoint}`,

      data: {
        operationName: "UserBalance",
        variables: {
          input: {
            wallet: addr,
            token: "0x0000000000000000000000000000000000000000",
          },
        },
        query:
          "query UserBalance($input: WalletBalanceInput) {\n  getUserBalanceByToken(input: $input) {\n    tokenAddress\n    walletAddress\n    lastBlockID\n    amount\n  }\n}\n",
      },
    });

    if (data.data.getUserBalanceByToken === null) {
      return {
        blockHeight: 0,
        balance: new BN(0),
        nonce: 0,
      };
    }

    return {
      blockHeight: data.data.getUserBalanceByToken.lastBlockID,
      balance: new BN(data.data.getUserBalanceByToken.amount),
      nonce: parseInt(nonce) + 1,
    };
  }

  async getTransactions(
    params: GetTransactionsParams
  ): Promise<TransactionSummary[]> {
    let { addr } = params;
    addr = addr.toLowerCase();

    const incoming_res = (
      await axios({
        method: "POST",
        url: `${this.endpoint}`,
        data: {
          operationName: "TxDetails",
          variables: {
            input: {
              tokenAddress: "0x0000000000000000000000000000000000000000",
              toAddress: addr,
            },
          },
          query:
            "query TxDetails($input: TransactionDetailsInput) {\n  getTransactionDetails(input: $input) {\n    list {\n    accepted\n     timestamp\n      blockId\n      txId\n      toAddress\n      fromAddress\n      tokenAddress\n      amount\n      cumulativeGas\n      gasPrice\n    }\n  }\n}\n",
        },
      })
    ).data.data;

    const outgoing_res = (
      await axios({
        method: "POST",
        url: `${this.endpoint}`,

        data: {
          operationName: "TxDetails",
          variables: {
            input: {
              tokenAddress: "0x0000000000000000000000000000000000000000",
              fromAddress: addr,
            },
          },
          query:
            "query TxDetails($input: TransactionDetailsInput) {\n  getTransactionDetails(input: $input) {\n    list {\n    accepted\n     timestamp\n      blockId\n      txId\n      toAddress\n      fromAddress\n      tokenAddress\n      amount\n      cumulativeGas\n      gasPrice\n    }\n  }\n}\n",
        },
      })
    ).data.data;

    const incoming =
      incoming_res && incoming_res.getTransactionDetails
        ? incoming_res.getTransactionDetails.list
        : [];
    const outgoing =
      outgoing_res && outgoing_res.getTransactionDetails
        ? outgoing_res.getTransactionDetails.list
        : [];
    const ret1: Array<TransactionSummary> = await Promise.all(
      incoming.map(async (tx: Transaction) => {
        const r: TransactionSummary = {
          id: tx.txId,
          fromAddress: tx.fromAddress,
          toAddress: tx.toAddress,
          timestamp: new Date(parseInt(tx.timestamp) / 1000),
          amount: new BN(tx.amount),
          fee: new BN(0),
          direction: "in",
        };
        return r;
      })
    );
    const ret2: Array<TransactionSummary> = await Promise.all(
      outgoing.map(async (tx: Transaction) => {
        const r: TransactionSummary = {
          id: tx.txId,
          fromAddress: tx.fromAddress,
          toAddress: tx.toAddress,
          timestamp: new Date(parseInt(tx.timestamp) / 1000),
          amount: new BN(tx.amount),
          fee: new BN(0),
          direction: "out",
        };
        return r;
      })
    );
    const ret = [...ret1, ...ret2];
    return ret;
  }

  private async post(query: string, variables: any): Promise<AxiosResponse> {
    const response = await axios.post(this.endpoint, {
      query,
      variables,
    });
    if (response.data.errors) {
      throw new Error(JSON.stringify(response.data.errors));
    }
    return response;
  }
}
