import React from "react";

interface OverlayProps {
  children: React.ReactNode;
}

const Overlay: React.FC<OverlayProps> = ({ children }) => {
  return (
    <div className="flex flex-col space-y-4 h-screen w-screen justify-center items-center bg-white-100 z-50 fixed top-0 left-0 bg-white  px-4">
      {children}
    </div>
  );
};

export default Overlay;
