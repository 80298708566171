import BN from "bn.js";
import Long from "long";

export type AddressBook = { [key: string]: string };
export interface ZilliqaTransaction {
  version: number;
  toAddr: string;
  amount: BN;
  gasPrice: BN;
  gasLimit: Long;

  code?: string;
  data?: string;
  nonce?: number;
  pubKey?: string;
  signature?: string;
}

export interface SerializedZilliqaTransaction {
  version: number;
  toAddr: string;
  amount: string;
  gasPrice: string;
  gasLimit: string;

  code?: string;
  data?: string;
  nonce?: number;
  pubKey?: string;
  signature?: string;
}

export const serializeTransaction = (transaction: ZilliqaTransaction): any => {
  const { amount, gasPrice, gasLimit, ...rest } = transaction;
  return {
    amount: amount.toString(),
    gasPrice: gasPrice.toString(),
    gasLimit: gasLimit.toString(),
    ...rest,
  };
};

export const deserializeTransaction = (
  serializedTransaction: any
): ZilliqaTransaction => {
  const { amount, gasPrice, gasLimit, ...rest } = serializedTransaction;
  return {
    amount: new BN(amount),
    gasPrice: new BN(gasPrice),
    gasLimit: Long.fromString(gasLimit),
    ...rest,
  };
};
