import React, { useState, useEffect } from "react";
import Container from "../components/Container";
import Overlay from "../components/Overlay";
import { Word } from "./WordListShow";
import { Account } from "../account/Account";
import { NUMBER_OF_WORDS } from "../account/KeyManagement";
import RecoveryCard from "./RecoveryCard";

interface WordListCreateImplProps {
  setValidation: (b: boolean) => void;
  setMnemonic: (mnemonic: string) => void;
}

interface WordListCreateProps {
  onPrev: () => void;
  onNext: (mnemonic: string) => void;
}

const WordCreateImpl: React.FC<WordListCreateImplProps> = ({
  setValidation,
  setMnemonic,
}) => {
  const [selectedWords, setSelectedWords] = useState<Word[]>([]);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setValidation(selectedWords.length === NUMBER_OF_WORDS);
    if (selectedWords.length === NUMBER_OF_WORDS) {
      let mnemonic = "";
      for (let i = 0; i < selectedWords.length; ++i) {
        if (i != 0) {
          mnemonic += " ";
        }
        mnemonic += selectedWords[i].value;
      }
      setMnemonic(mnemonic);
    }
  }, [selectedWords]);
  const addWord = (word: Word) => {
    setSelectedWords((prevSelectedWords) => [...prevSelectedWords, word]);
  };

  const removeWord = (word: Word) => {
    setSelectedWords((prevSelectedWords) =>
      prevSelectedWords.filter((w) => w.id !== word.id)
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const value = inputValue.toLowerCase().trim();
    if (value === "") {
      return;
    }
    addWord({ id: selectedWords.length, value });
    setInputValue("");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      return;
    }
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[a-zA-Z]+$/; // regular expression for alphabetic characters
    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <RecoveryCard>
      <ul className="flex flex-col gap-2">
        {selectedWords.map((word, index) => (
          <li
            key={word.value + word.id}
            className="flex justify-between items-center px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200"
          >
            <span>
              {index + 1}. {word.value}
            </span>
            <svg
              onClick={() => removeWord(word)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500 hover:text-gray-700"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.293-9.707a1 1 0 00-1.414-1.414L10 8.586l-1.879-1.879a1 1 0 00-1.414 1.414L8.586 10l-1.879 1.879a1 1 0 001.414 1.414L10 11.414l1.879 1.879a1 1 0 001.414-1.414L11.414 10l1.879-1.879z"
                clipRule="evenodd"
              />
            </svg>
          </li>
        ))}
      </ul>
      <form onSubmit={handleSubmit} className="flex w-full flex-grow-0">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="inputField"
          disabled={selectedWords.length >= NUMBER_OF_WORDS}
          onKeyPress={handleKeyPress}
        />
        <button
          type="submit"
          className="sendButton"
          disabled={selectedWords.length >= NUMBER_OF_WORDS}
        >
          Add
        </button>
      </form>
    </RecoveryCard>
  );
};

const WordCreate: React.FC<WordListCreateProps> = ({ onPrev, onNext }) => {
  const [canContinue, setCanContinue] = useState(false);
  const [mnemonic, setMnemonic] = useState("");
  return (
    <Overlay>
      <Container justify="center">
        <p className="text-center">Please enter your recovery phrase.</p>
        <WordCreateImpl
          setValidation={setCanContinue}
          setMnemonic={setMnemonic}
        />
        <div className="flex flex-row w-full space-x-2">
          <button
            onClick={onPrev}
            type="submit"
            className="flex-grow border border-teal-500 hover:bg-gray-300 text-teal-500 text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
          >
            Back
          </button>
          <button
            disabled={!canContinue}
            onClick={() => canContinue && onNext(mnemonic)}
            type="submit"
            className={
              (canContinue
                ? "bg-teal-500 hover:bg-teal-700 text-white"
                : "bg-gray-300 text-white ") +
              " flex-grow text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
            }
          >
            Next
          </button>
        </div>
      </Container>
    </Overlay>
  );
};

export default WordCreate;
