import { bech32, bech32m } from "bech32";
import BN from "bn.js";
import hashjs from "hash.js";

const HRP = "zil";
export function bnHasValue(bn: BN | undefined): boolean {
  if (bn === undefined) {
    return false;
  }
  const arr = bn.toArray();
  if (!bn || arr.length === 0 || (arr.length === 1 && arr[0] === 0)) {
    return false;
  }
  return true;
}

export function formatBN(bn: BN | undefined, divisor?: number): string {
  if (bn === undefined) {
    return "N/A";
  }

  if (!bnHasValue(bn)) {
    return "0";
  }
  divisor = divisor || 1;
  try {
    let n = bn.toNumber() / divisor;
    let formatted = n.toString();

    formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatted;
  } catch (error) {
    return "N/A";
  }
}

export const formatZil = (value: BN | undefined): string => {
  if (value === undefined) {
    return "N/A";
  }
  if (!bnHasValue(value)) {
    return "0 ZIL";
  }
  const zil = value.div(new BN(10).pow(new BN(12)));
  if (zil.gte(new BN(1))) {
    return `${formatBN(value.div(new BN(10).pow(new BN(10))), 100)} ZIL`;
  }
  const li = value.div(new BN(10).pow(new BN(9)));
  if (li.gte(new BN(1))) {
    return `${formatBN(value.div(new BN(10).pow(new BN(7))), 100)} LI`;
  }
  return `${formatBN(value)} Qa`;
};

export const getAccountColor = (index: number): string => {
  const colors = [
    "black",
    "red-700",
    "yellow-600",
    "green-700",
    "blue-700",
    "indigo-700",
    "purple-700",
    "pink-700",
    "orange-700",
    "teal-700",
    "emerald-700",
    "lime-700",
    "cyan-700",
    "fuchsia-700",
    "violet-700",
    "rose-700",
    "amber-700",
    "trueGray-700",
    "warmGray-700",
    "gray-900",
  ];

  if (index >= 0 && index < colors.length) {
    return colors[index];
  } else {
    return "gray-500";
  }
};

export const toChecksumAddress = (address: string): string => {
  address = address.toLowerCase();
  if (!address.startsWith("0x")) {
    throw new Error(`${address} is not a valid base 16 address`);
  }

  address = address.replace("0x", "");
  const hash = hashjs.sha256().update(address, "hex").digest("hex");
  const v = new BN(hash, "hex", "be");
  let ret = "0x";

  for (let i = 0; i < address.length; i++) {
    if ("0123456789".indexOf(address[i]) !== -1) {
      ret += address[i];
    } else {
      ret += v.and(new BN(2).pow(new BN(255 - 6 * i))).gte(new BN(1))
        ? address[i].toUpperCase()
        : address[i].toLowerCase();
    }
  }

  return ret;
};

export function fromBech32(value: string): string {
  let decoded;

  try {
    decoded = bech32.decode(value);
  } catch (err) {
    throw new Error("Zilliqa address cannot be decoded.");
  }

  const prefix = decoded.prefix;
  if (prefix != HRP) {
    throw new Error("HPR mismatch: This is not a Zilliqa address.");
  }

  const pubkey = Buffer.from(bech32.fromWords(decoded.words));

  return toChecksumAddress("0x" + pubkey.toString("hex"));
}

export function toBech32(pubkey: string): string {
  if (pubkey.substring(0, 2) === "0x") {
    pubkey = pubkey.substring(2, pubkey.length);
  }

  const payload = Buffer.from(pubkey, "hex");
  const words = bech32.toWords(payload);

  return bech32.encode(HRP, words);
}
