import React, { useState, useEffect } from "react";
import Container from "../components/Container";
import Overlay from "../components/Overlay";
import AccountSetupFront from "./AccountSetupFront";
import WordListShow, { Word } from "./WordListShow";
import WordListVerify from "./WordListVerify";
import WordListCreate from "./WordListCreate";
import { ZilliqaGraphQLApiClient } from "../api/Indexer";
import { bnHasValue, getAccountColor } from "../account/Utils";
import { STORAGE_SEED_PHRASE } from "../account/Storage";

import Loading from "../components/Loading";
import {
  generateMnemonic,
  encryptMessage,
  decryptMessage,
  getRandomPassword,
  getZilliqaAccount,
} from "../account/KeyManagement";
import { Account } from "../account/Account";

type CurrentStep =
  | "start"
  | "generateKey"
  | "showKey"
  | "createKey"
  | "verifyKey"
  | "encryptingKeys"
  | "discoveringAccounts"
  | "failed";

interface Props {
  onCancel?: () => void;
  setEncryptionKey?: (password: string, encryptedPassword: string) => void;
  setAccounts?: Account[];
  onDone: (a: Account[]) => void;
}

async function generateSecret(): Promise<string> {
  return generateMnemonic();
}

export const mnemonicToWordList = (mnemonic: string): Word[] => {
  const words = mnemonic.split(" ");
  return words.map((word, index) => ({ id: index, value: word }));
};

function shuffleArray<T>(array: T[]): T[] {
  // Clone the array to avoid modifying the original
  const shuffledArray = [...array];

  // Use the Fisher-Yates shuffle algorithm to randomly sort the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
}

async function encryptMnemonic(mnemonic: string, masterPassword: string) {
  return encryptMessage(mnemonic, masterPassword);
}

async function discoverAccounts(
  mnemonic: string,
  password: string,
  updateStatus: (x: string) => void
): Promise<Account[]> {
  let ret: Account[] = [];

  let done = false;
  let index = 0;

  const client = new ZilliqaGraphQLApiClient();
  while (!done) {
    const next_account = getZilliqaAccount(index, mnemonic, password);

    const details = await client.getAccountBalance(next_account.bech32Address);

    next_account.balance = details.balance;
    next_account.color = getAccountColor(index);

    ret.push(next_account);
    done = !bnHasValue(details.balance);
    index += 1;
    updateStatus(`Found wallet: ${next_account.path}`);
  }

  return ret;
}

const AccountSetup: React.FC<Props> = ({
  onCancel,
  setEncryptionKey,
  setAccounts,
  onDone,
}) => {
  const [masterPassword, setMasterPassword] = useState("");
  const [state, setState] = useState<CurrentStep>("start");
  const [mnemonic, setMnemonic] = useState("");
  const [error, setError] = useState("");
  const [words, setWords] = useState<Word[]>([]);
  const [shuffledWords, setShuffledWords] = useState<Word[]>([]);
  const [discoveryText, setDiscoveryText] = useState("");

  useEffect(() => {
    const next_words = mnemonicToWordList(mnemonic);
    setWords(next_words);
    setShuffledWords(shuffleArray(next_words));
  }, [mnemonic]);

  const finishPasswordSetup = (
    password: string,
    localMnemonic: string,
    masterPassword: string,
    encryptedSeed: string
  ) => {
    if (setEncryptionKey) {
      setEncryptionKey(password, masterPassword);
    }

    localStorage.setItem(STORAGE_SEED_PHRASE, encryptedSeed);
    setMasterPassword("");

    setState("discoveringAccounts");
    setDiscoveryText("Discovering accounts.");
    setTimeout(
      () =>
        discoverAccounts(localMnemonic, password, setDiscoveryText)
          .then((accounts) => {
            setMnemonic("");
            onDone(accounts);
          })
          .catch((error) => {
            console.error("Something went wrong:", error);
          }),
      50
    );
  };

  const setMasterSeedAndPassword = (localMnemonic: string) => {
    setTimeout(() => {
      const password = getRandomPassword();
      encryptMnemonic(localMnemonic, masterPassword).then(
        (encryptedSeed: string) => {
          finishPasswordSetup(
            password,
            localMnemonic,
            masterPassword,
            encryptedSeed
          );
        }
      );
    }, 50);
  };

  return (
    <Overlay>
      <Container justify="center">
        {state == "start" && (
          <AccountSetupFront
            onCancel={onCancel}
            onImportAccount={(n: string) => {
              setMasterPassword(n);
              setState("createKey");
            }}
            onGenerateNewAccount={(n: string) => {
              setMasterPassword(n);
              setState("generateKey");
              generateSecret()
                .then((newMnemonic: string) => {
                  setMnemonic(newMnemonic);
                  setState("showKey");
                })
                .catch((error) => {
                  console.error(error);
                  setError(error);
                  setState("failed");
                });
            }}
          />
        )}
        {state == "generateKey" && <Loading text="Generating key ..." />}
        {state == "showKey" && (
          <WordListShow
            words={words}
            onPrev={() => setState("start")}
            onNext={() => setState("verifyKey")}
          />
        )}
        {state == "createKey" && (
          <WordListCreate
            onPrev={() => setState("start")}
            onNext={(newMnemonic: string) => {
              setMnemonic(newMnemonic);
              setState("encryptingKeys");
              setMasterSeedAndPassword(newMnemonic);
            }}
          />
        )}

        {state == "verifyKey" && (
          <WordListVerify
            words={shuffledWords}
            onPrev={() => setState("showKey")}
            onNext={() => {
              setState("encryptingKeys");
              setMasterSeedAndPassword(mnemonic);
            }}
          />
        )}

        {state == "encryptingKeys" && (
          <Loading text="Encrypting and storing wallet." />
        )}
        {state == "discoveringAccounts" && <Loading text={discoveryText} />}
      </Container>
    </Overlay>
  );
};

export default AccountSetup;
