// ChatComponent.tsx
import React, { useState, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";
import "./ChatComponent.css";
import {
  STORAGE_OPENAI_API_KEY,
  STORAGE_ASSISTANT_MESSAGES,
} from "../account/Storage";
import { ApiKeyForm } from "./ApiKey";
import { Configuration, OpenAIApi } from "openai";
import { runCodeInSandbox } from "./Sandbox";
import ImageCollection, { Image } from "./ImageCollection";
import TransactionWidget from "./TransactionWidget";
import {
  AddressBook,
  deserializeTransaction,
  ZilliqaTransaction,
  SerializedZilliqaTransaction,
} from "../types";
import BN from "bn.js";
import Long from "long";

export type ChatStateType = "waiting" | "ready";
interface ChatInterface {
  onChangeState: (s: ChatStateType) => void;
}

interface Message {
  id: number;
  text: string;
  element?: JSX.Element;
  me: boolean;
}

interface ChatComponentInnerInterface {
  apiKey: string;
  onChangeState: (s: ChatStateType) => void;
}

interface MessageInterface {
  role: string;
  content: string;
}

interface ChatCompletionParams {
  model: string;
  messages: MessageInterface[];
}

async function classifyRelation(apiKey: string, message: string) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  const data = {
    model: "text-davinci-003",
    prompt:
      "Classify following message as `zilliqa related`, `zil related` or `other`. The message `" +
      message +
      "` is`",
    temperature: 0,
    max_tokens: 1000,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  };

  const response = await axios.post(
    "https://api.openai.com/v1/completions",
    data,
    { headers }
  );

  const ret = response.data.choices[0].text.trim().toLowerCase();
  return ret.replace(/[^a-zA-Z ]/g, "");
}

async function classifyQuery(apiKey: string, message: string) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  const data = {
    model: "text-davinci-003",
    prompt:
      "Classify following message as `api query request`, `api transaction request`, `nft creation` or `generic message`. The message `" +
      message +
      "` is`",
    temperature: 0,
    max_tokens: 1000,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  };

  const response = await axios.post(
    "https://api.openai.com/v1/completions",
    data,
    { headers }
  );

  const ret = response.data.choices[0].text.trim().toLowerCase();
  return ret.replace(/[^a-zA-Z ]/g, "");
}

async function genericMessage(apiKey: string, messages: MessageInterface[]) {
  const params: ChatCompletionParams = {
    model: "gpt-3.5-turbo",
    messages,
  };

  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    },
  };

  const response = await axios.post(
    "https://api.openai.com/v1/chat/completions",
    params,
    config
  );

  try {
    return response.data.choices[0].message.content;
  } catch (error: any) {
    return `(messsage lost: ${error.message})`;
  }
}

async function generateResponse(
  apiKey: string,
  result: string,
  question: string
) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  const data = {
    model: "text-davinci-003",
    prompt: `Question: What is the transaction rate?
Result: 0.15541161676969495
Answer: The transaction rate is 0.16 transaction/second

Question: What is the balance of Peters account?
Result: 3212.5
Answer: The balance of Peters account is 3212.5 ZIL

Question: ${question}
Result: ${result}
    `,
    temperature: 0.8,
    max_tokens: 60,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  };

  console.log(`
Question: ${question}
Result: ${result}`);
  const response = await axios.post(
    "https://api.openai.com/v1/completions",
    data,
    { headers }
  );
  let answer = response.data.choices[0].text;
  if (answer.includes("Answer:")) {
    answer = answer.split("Answer:", 2)[1].trim();
  }
  return answer;
}

async function makeQuery(apiKey: string, question: string, context: string) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  const query = `"""
Following Typescript Zilliqa related functions exist:
getDSBlockListing(index: number): { BlockNum: string, Hash: string };
getZilPriceInUsd(): number;
getBlockChainInfo(): { CurrentDSEpoch: string, CurrentMiniEpoch: string, DSBlockRate: number, NumDSBlocks: string, NumPeers: number, NumTransactions: string, NumTxBlocks: string, NumTxnsDSEpoch: string, NumTxnsTxEpoch: string };
getBalance(address: string): { balance: string, nonce: number };
getTransactionRate(): number;
getTotalCoinSupply(): number;
getTxBlockRate(): number;

Following context is relevant:
${context}

Following generic functions exists:
fail(why:string): void;

Example queries:
getLatestTxBlock()
getRecentTransactions().TxnHashes.map((h)=> getTransaction(h))
getBlockchainInfo()
getLatestDsBlock().header.PoWWinners
getTransactionRate()   ///< Gets the transaction rate
1./parseFloat(getTransactionRate()) ///< Gets the number of transactions per second
getMinerInfo(getCurrentDSEpoch()) //< Gets miner info
getMinerInfo(getCurrentDSEpoch()).dscommittee //< Gets miner identities
getTotalCoinSupply() //< Total supply
getTxBlockRate()  //< Block rate
1./parseFloat(getTxBlockRate())  //< Block time


Storing the answer in 'result', make function calls to answer following questions: '${question}'
"""`;

  const data = {
    model: "text-davinci-003",
    prompt: query,
    temperature: 0,
    max_tokens: 200,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  };

  const response = await axios.post(
    "https://api.openai.com/v1/completions",
    data,
    { headers }
  );

  const generated_query = response.data.choices[0].text;
  console.log("Query:", context + "\n" + generated_query);
  const result = await runCodeInSandbox(context + "\n" + generated_query);
  const answer = await generateResponse(apiKey, result, question);
  return answer;
}

const generateImage = async (
  prompt: string,
  size: string,
  n: number,
  apiKey: string
) => {
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/images/generations",
      {
        prompt,
        size,
        n,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

interface NftParameters {
  n: number;
  size: string;
  prompt: string;
}

const parseNftParameters = (text: string): NftParameters => {
  const obj: { n: string; size: string; prompt: string } = {
    n: "",
    size: "",
    prompt: "",
  };
  text.split("\n").forEach((line) => {
    const [key, value] = line.split(": ");
    if (key && value) {
      obj[key.trim().toLowerCase() as keyof NftParameters] = value.trim();
    }
  });
  let size = obj.size;
  if (!size.includes("x")) {
    size = "512x512";
  }
  return {
    n: parseInt(obj.n),
    size,
    prompt: obj.prompt,
  };
};

async function getNftParameters(
  apiKey: string,
  question: string
): Promise<NftParameters> {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  const data = {
    model: "text-davinci-003",
    prompt: `Question: Could you make a nft containing a girl skull with punk purple and blue-ish hair?
N: 1
Size: 512x512
Prompt: A girl skull with punk purple and blue-ish hair

Question: Could you make 17 fluffy cats in vibrant colors in small image sizes?
N: 17
Size: 128x128
Prompt: fluffy cats in vibrant colors

Question: ${question}
    `,
    temperature: 0.8,
    max_tokens: 60,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  };

  console.log(`
Question: ${question}`);

  const response = await axios.post(
    "https://api.openai.com/v1/completions",
    data,
    { headers }
  );
  let answer = response.data.choices[0].text.trim();

  return parseNftParameters(answer);
}

async function getSendTransactionParameters(
  apiKey: string,
  question: string,
  addressBook: AddressBook
): Promise<SerializedZilliqaTransaction> {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };

  const data = {
    model: "text-davinci-003",
    prompt: `Question: Could you send Alice 10.5 ZIL?
Address Book: {"Peter":"zil1n68nzazjqu6v29df6x3q6erwgg6q7m6j8d6qcz", "Alice":"zil1l6fku49pz9rzq3gknwhv0jzjj3x6au2a6zu0ly", "Bob":"zil1dmv7hnepa92vj6qdx3wakwss6v64at6vn8d9xz"}
Amount: 10.5
Answer: {"version": "0", "toAddr": "zil1l6fku49pz9rzq3gknwhv0jzjj3x6au2a6zu0ly", "amount": "10500000000000", "gasPrice": "2000000000", "gasLimit": "50"}

Question: Could you send 29.7 ZIL to zil1km73pxz0lny8l4w4zx4hcr2l4s4nw0s9ht7t32?
Address Book: {"Peter":"zil1n68nzazjqu6v29df6x3q6erwgg6q7m6j8d6qcz", "Alice":"zil1l6fku49pz9rzq3gknwhv0jzjj3x6au2a6zu0ly", "Bob":"zil1dmv7hnepa92vj6qdx3wakwss6v64at6vn8d9xz"}
Amount: 29.7
Answer: {"version": "0", "toAddr": "zil1km73pxz0lny8l4w4zx4hcr2l4s4nw0s9ht7t32", "amount": "29700000000000", "gasPrice": "2000000000", "gasLimit": "50"}

Question: ${question}
Address Book: ${JSON.stringify(addressBook)}
`,
    temperature: 0.8,
    max_tokens: 200,
    top_p: 1.0,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  };
  const response = await axios.post(
    "https://api.openai.com/v1/completions",
    data,
    { headers }
  );
  let answer = response.data.choices[0].text.trim();
  console.log(data);
  console.log(response.data.choices);
  console.log(answer);
  const parts = answer.split("Answer:", 2);
  let ret = JSON.parse(parts[1]);
  const amount = parseFloat(parts[0].split("Amount:", 2)[1]) * 1000;
  ret.amount = amount.toString() + "000000000";

  return ret;
}

async function sendMessageToOpenAI(
  apiKey: string,
  messages: MessageInterface[],
  addressBook: AddressBook
): Promise<string | JSX.Element> {
  const type = await classifyQuery(
    apiKey,
    messages[messages.length - 1].content
  );
  const relation = await classifyRelation(
    apiKey,
    messages[messages.length - 1].content
  );
  console.log(type, relation, messages[messages.length - 1].content);

  if (type.includes("nft")) {
    const parameters = await getNftParameters(
      apiKey,
      messages[messages.length - 1].content
    );

    const image = await generateImage(
      parameters.prompt,
      parameters.size,
      parameters.n,
      apiKey
    );
    let ret = "";
    for (let i = 0; i < image.data.length; ++i) {
      if (i !== 0) {
        ret += "\n";
      }
      ret += "image:" + image.data[i].url;
    }

    return ret;
  } else if (type.includes("transaction")) {
    const transaction_details = await getSendTransactionParameters(
      apiKey,
      messages[messages.length - 1].content,
      addressBook
    );
    console.log(transaction_details);

    const transaction = deserializeTransaction(transaction_details);
    return (
      <TransactionWidget transaction={transaction} addressBook={addressBook} />
    );
  } else if (type.includes("query")) {
    console.log("Createing query request");
    let context = "";
    for (const [key, value] of Object.entries(addressBook)) {
      context += `let ${key}Address = "${value}";\n`;
    }
    return makeQuery(apiKey, messages[messages.length - 1].content, context);
  }

  if (relation.includes("zil")) {
    return genericMessage(apiKey, messages);
  }

  return "I am only programmed to answer messages related to Zilliqa.";
}

function addressBookToContext(obj: { [key: string]: any }): string {
  return Object.entries(obj)
    .map(([key, value]) => `${key}:${value}`)
    .join(",");
}

const ChatComponentInner: React.FC<ChatComponentInnerInterface> = ({
  apiKey,
  onChangeState,
}) => {
  const [messages, setMessages] = useState<Message[]>([
    {
      id: 0,
      text: "Hello! How can I help?",

      me: false,
    },
  ]);
  const [inputValue, setInputValue] = useState<string>("");
  const [canType, setCanType] = useState(true);

  useEffect(() => {
    try {
      const storedMessages = localStorage.getItem(STORAGE_ASSISTANT_MESSAGES);
      if (storedMessages) {
        // TODO: Object serialization not working setMessages(JSON.parse(storedMessages));
      }
    } catch (error) {
      console.error("Error while restoring the chat: " + error);
    }
  }, []);

  useEffect(() => {
    if (messages.length > 1) {
      localStorage.setItem(
        STORAGE_ASSISTANT_MESSAGES,
        JSON.stringify(messages)
      );
    }
  }, [messages]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputValue.trim() === "") {
      return;
    }

    let local_messages = [
      ...messages,
      { id: Date.now(), text: inputValue.trim(), me: true },
    ];
    onChangeState("waiting");
    setMessages(local_messages);
    setInputValue("");
    setCanType(false);

    const addressBook = {
      James: "zil1er32603wwalf3kahhr489ej4cpqq2k63pgsgs3",
      Alice: "zil1n68nzazjqu6v29df6x3q6erwgg6q7m6j8d6qcz",
      Bob: "zil1pyvnufd73yfj0guk57ezw60w34ehs2s4j4s9wr",
    };

    sendMessageToOpenAI(
      apiKey,
      [{ role: "user", content: inputValue.trim() }],
      addressBook
    )
      .then((response: string | JSX.Element) => {
        if (typeof response === "string") {
          let res = response.trim();
          let new_message: Message = { id: Date.now(), text: "", me: false };

          if (!res.startsWith("image:")) {
            new_message.text = res;
          } else {
            let nfts: Image[] = [];
            const images = res.split("\n");

            for (let i = 0; i < images.length; ++i) {
              const image = images[i];
              nfts.push({
                src: image.split("image:", 2)[1],
                alt: image.split("image:", 2)[1],
              });
            }
            new_message.element = <ImageCollection images={nfts} />;
          }
          setMessages([...local_messages, new_message]);
          onChangeState("ready");
          setCanType(true);
        } else {
          let new_message: Message = {
            id: Date.now(),
            text: "",
            element: response as JSX.Element,
            me: false,
          };

          setMessages([...local_messages, new_message]);
          onChangeState("ready");
          setCanType(true);
        }
      })
      .catch((error: string) => {
        setMessages([
          ...local_messages,
          {
            id: Date.now(),
            text: "Something went wrong with this query: " + error,
            me: false,
          },
        ]);
        onChangeState("ready");
        setCanType(true);
      });
  };

  return (
    <div className="chatCcontainer">
      <div className="chatBox">
        <div className="chatBoxInner">
          {messages.map((message) => (
            <div
              className={
                "messageContainer " + (message.me ? " justify-end" : "")
              }
            >
              <div
                key={message.id}
                className={message.me ? "messageFromMe" : "messageFromGpt"}
              >
                {message.element}
                {message.text}
              </div>
            </div>
          ))}
        </div>
      </div>
      <form onSubmit={handleSubmit} className="inputForm">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="inputField"
          disabled={!canType}
        />
        <button type="submit" className="sendButton" disabled={!canType}>
          Send
        </button>
      </form>
    </div>
  );
};

const ChatComponent: React.FC<ChatInterface> = ({ onChangeState }) => {
  const [apiKey, setApiKey] = useState<string>("");

  useEffect(() => {
    const storedApiKey = localStorage.getItem(STORAGE_OPENAI_API_KEY);
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  if (apiKey === "") {
    return <ApiKeyForm onSubmit={setApiKey} />;
    //    return <div>No API found.</div>;
  }
  return <ChatComponentInner apiKey={apiKey} onChangeState={onChangeState} />;
};

export default ChatComponent;
