import BN from "bn.js";

export interface Account {
  index: number;
  accountName: string;
  encryptedPrivateKey: string;
  publicKey: string;
  address: string;
  bech32Address: string;

  // Optional data
  balance?: BN;
  path?: string;
  color?: string;
}

export interface SerializedAccount {
  index: number;
  accountName: string;
  encryptedPrivateKey: string;
  publicKey: string;
  address: string;
  bech32Address: string;
  // Optional
  balance?: string;
  path?: string;
  color?: string;
}

export const serializeAccount = (account: Account): SerializedAccount => {
  const serialized: SerializedAccount = {
    index: account.index,
    accountName: account.accountName,
    encryptedPrivateKey: account.encryptedPrivateKey,
    publicKey: account.publicKey,
    address: account.address,
    bech32Address: account.bech32Address,
  };

  if (account.balance) {
    serialized.balance = account.balance.toString();
  }

  if (account.path) {
    serialized.path = account.path;
  }

  if (account.color) {
    serialized.color = account.color;
  }

  return serialized;
};

export const deserializeAccount = (serialized: SerializedAccount): Account => {
  const account: Account = {
    index: serialized.index,
    accountName: serialized.accountName,
    encryptedPrivateKey: serialized.encryptedPrivateKey,
    publicKey: serialized.publicKey,
    address: serialized.address,
    bech32Address: serialized.bech32Address,
  };

  if (serialized.balance) {
    account.balance = new BN(serialized.balance);
  }

  if (serialized.path) {
    account.path = serialized.path;
  }

  if (serialized.color) {
    account.color = serialized.color;
  }

  return account;
};

export function serializeAccounts(accounts: Account[]): string {
  return JSON.stringify(
    accounts.map((account: Account) => serializeAccount(account))
  );
}

export function deserializeAccounts(serializedAccounts: string): Account[] {
  const accountsData = JSON.parse(serializedAccounts);
  const ret = accountsData.map((accountData: SerializedAccount) =>
    deserializeAccount(accountData)
  );
  return ret;
}
