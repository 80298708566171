import React from "react";

const Logo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 308.1 436.1"
      className={className}
    >
      <g transform="translate(190.693 -109)">
        <path
          id="path0_fill"
          d="M117.4 289.2l-62.8 28L-112 402l166.6 80.1V349.7l62.8-28.3V517l-62.8 28-245.3-117.4v-62.4l169.8-83.3-169.8-81.6V137l63.8-28 244.3 117.6v62.6z"
        />
      </g>
      <g transform="translate(190.693 -109)">
        <path
          id="path1_fill"
          d="M-190.7 137L54.6 254.6l62.8-28L-126.9 109l-63.8 28z"
        />
      </g>
      <g transform="translate(208.695 -100.365)">
        <path id="path2_fill" d="M36.6 245.7l62.8-28v62.6l-62.8 28v-62.6z" />
      </g>
      <g transform="translate(190.693 -106.944)">
        <path
          id="path3_fill"
          d="M-190.7 135v63.3l169.8 81.6-169.8 83.3v62.4L54.6 543v-62.8L-112 400l166.6-84.8v-62.6L-190.7 135z"
        />
      </g>
      <g transform="translate(208.695 -93.411)">
        <path id="path4_fill" d="M36.6 529l62.8-28V305.4l-62.8 28.3V529z" />
      </g>
    </svg>
  );
};

export default Logo;
