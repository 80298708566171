const injectedFunctions = `
function getZilPriceInUsd() {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', 'https://api.binance.com/api/v3/ticker/price?symbol=ZILUSDT', false);  // false makes the request synchronous
  xhr.send(null);

  if (xhr.status === 200) {
    const response = JSON.parse(xhr.responseText);
    if(response.price)
    {
      return response.price;
    }
  }

  throw new Error('Failed to get ZIL price in USD');  
}

function getZilPriceInBtc() {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', 'https://api.binance.com/api/v3/ticker/price?symbol=ZILBTC', false);  // false makes the request synchronous
  xhr.send(null);

  if (xhr.status === 200) {
    const response = JSON.parse(xhr.responseText);
    if(response.price)
    {
      return response.price;
    }
  }
  throw new Error('Failed to get ZIL price in BTC');  
}

function getZilPriceInEth() {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', 'https://api.binance.com/api/v3/ticker/price?symbol=ZILETH', false);  // false makes the request synchronous
  xhr.send(null);

  if (xhr.status === 200) {
    const response = JSON.parse(xhr.responseText);
    if(response.price)
    {
      return response.price;
    }
  } 
  throw new Error('Failed to get ZIL price in ETH');
  
}

function getZilPriceInBnb() {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', 'https://api.binance.com/api/v3/ticker/price?symbol=ZILBNB', false);  // false makes the request synchronous
  xhr.send(null);

  if (xhr.status === 200) {
    const response = JSON.parse(xhr.responseText);
    if(response.price)
    {
      return response.price;
    }
  } 
  throw new Error('Failed to get ZIL price in BNB');  
}


function getBalance(address) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.zilliqa.com/", false);
  xhr.setRequestHeader("Content-Type", "application/json");
  console.log("Address:",address);
  var data = JSON.stringify({
    id: "1",
    jsonrpc: "2.0",
    method: "GetBalance",
    params: [address],
  });

  xhr.send(data);

  if (xhr.status === 200) {
    var response = JSON.parse(xhr.responseText);
    if (response && response.result) {
      if(response.result.balance.length < 9)
      {
        return 0;
      }
      return parseFloat(response.result.balance.substring(0, response.result.balance.length - 9)) / 1000.;
    }
    if (response && response.error) {
      throw new Error('Error getting balance: '+ response.error.message);
    }    
  }

  throw new Error('Error getting balance.');
}


function getBlockChainInfo() {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.zilliqa.com/", false);
  xhr.setRequestHeader("Content-Type", "application/json");
  const requestData = {
    id: "1",
    jsonrpc: "2.0",
    method: "GetBlockchainInfo",
    params: [""]
  };
  xhr.send(JSON.stringify(requestData));
  while (xhr.readyState !== 4) {
    // wait for request to complete
  }
  const response = JSON.parse(xhr.responseText);
  return response.result;
}

function getDsBlock(index) {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://api.zilliqa.com/', false);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify({
    id: '1',
    jsonrpc: '2.0',
    method: 'GetDsBlock',
    params: [index.toString()],
  }));
  if (xhr.status === 200) {
    const response = JSON.parse(xhr.responseText);
    if (response.result) {
      return {
        BlockNum: response.result.header.BlockNum,
        Hash: response.result.header.Hash,
      };
    }
  }
  return null;
}

function getTransactionRate() {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.zilliqa.com/", false);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(JSON.stringify({
    "id": "1",
    "jsonrpc": "2.0",
    "method": "GetTransactionRate",
    "params": [""]
  }));

  if (xhr.status === 200) {
    const response = JSON.parse(xhr.responseText);
    return response.result;
  } else {
    console.log(xhr);
    console.log(xhr.responseText);
    throw new Error("Error getting transaction rate: " + xhr.status);
  }
}

function getTotalCoinSupply() {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://api.zilliqa.com/', false);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify({
    id: '1',
    jsonrpc: '2.0',
    method: 'GetTotalCoinSupply',
    params: ['']
  }));

  if (xhr.status === 200) {
    const response = JSON.parse(xhr.responseText);
    return response.result;
  } else {
    throw new Error('Failed to fetch total coin supply');
  }
}

`;
export const runCodeInSandbox = async (code: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement("iframe");
    iframe.sandbox.add("allow-scripts");
    iframe.srcdoc = `
      <html>
        <body>
          <script>
            ${injectedFunctions}

            try {
              ${code}

              parent.postMessage({ result }, '*');
            } catch(error) {
              parent.postMessage({ error }, '*');              
            }
          </script>
        </body>
      </html>
    `;

    const messageHandler = (event) => {
      if (event.source === iframe.contentWindow) {
        window.removeEventListener("message", messageHandler);
        iframe.remove();
        if (event.data.error) {
          reject(event.data.error);
        }
        resolve(event.data.result);
      }
    };
    window.addEventListener("message", messageHandler);

    iframe.onload = () => {
      iframe.contentWindow.postMessage({}, "*");
    };

    document.body.appendChild(iframe);
  });
};
