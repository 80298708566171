// ChatComponent.tsx
import React, { useState, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";
import "./ChatComponent.css";
import { STORAGE_OPENAI_API_KEY } from "../account/Storage";

interface ApiKeyFormProps {
  onSubmit?: (apiKey: string) => void;
}

export const ApiKeyForm: React.FC<ApiKeyFormProps> = ({ onSubmit }) => {
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    const storedApiKey = localStorage.getItem(STORAGE_OPENAI_API_KEY);
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  useEffect(() => {
    if (apiKey !== "") {
      localStorage.setItem(STORAGE_OPENAI_API_KEY, apiKey);
    }
  }, [apiKey]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(apiKey);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-start w-full">
      <label htmlFor="api-key" className="text-xl mb-2">
        Enter your OpenAI API key
      </label>
      <input
        type="password"
        id="api-key"
        name="api-key"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        className="border-2 border-gray-200 rounded px-4 py-2 mb-4 w-full focus:outline-none focus:border-teal-400"
        placeholder="Your API Key"
        required
      />
      <button
        type="submit"
        className="bg-teal-500 hover:bg-teal-600 text-white py-2 px-6 rounded focus:outline-none"
      >
        Set
      </button>
    </form>
  );
};
