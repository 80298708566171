import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { virtualize, bindKeyboard } from "react-swipeable-views-utils";
import { mod } from "react-swipeable-views-core";
import QRCode from "qrcode.react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Loading from "./Loading";
import { Account } from "../account/Account";
import { formatZil } from "../account/Utils";
import Logo from "./Logo";
import "./Glass.css";

interface AccountSliderInterface {
  accounts: Account[];
  children?: any;
  onChange?: (i: number) => void;
}

interface Props {
  index: number;
  accounts: Account[];
}
const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const SlideRenderer: React.FC<Props> = ({ index, accounts }) => {
  if (accounts.length === 0) {
    return (
      <div className="w-full h-full flex justify-center items-center select-none">
        <Loading text="Loading accounts." />
      </div>
    );
  }
  const account = accounts[mod(index, accounts.length)];
  return (
    <div className="w-full h-full flex justify-center items-center select-none">
      <div className="relative w-80 h-52 rounded-lg overflow-hidden shadow-xl">
        <div className={"absolute inset-0 bg-" + (account.color || "black")}>
          <svg
            className="shiny-metal"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0" y="0" width="100" height="100" fill="url(#gradient)" />
            <defs>
              <linearGradient id="gradient">
                <stop offset="0%" stop-color="rgba(255, 255, 255, 0.3)" />
                <stop offset="10%" stop-color="rgba(255, 255, 255, 0.7)" />
                <stop offset="40%" stop-color="rgba(255, 255, 255, 0.8)" />
                <stop offset="60%" stop-color="rgba(255, 255, 255, 0.8)" />
                <stop offset="80%" stop-color="rgba(255, 255, 255, 0.7)" />
                <stop offset="100%" stop-color="rgba(255, 255, 255, 0.3)" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="absolute inset-0 z-10 flex flex-col justify-between p-6">
          <div className="flex justify-between items-start">
            <div className="text-lg font-bold text-white">
              {account.accountName}
            </div>
            <div className="h-12 w-12 mr-4">
              <Logo className="opacity-25" />
            </div>
          </div>
          <div className="text-white text-sm overflow-hidden">
            <div className="w-full">{account.address}</div>
            <div className="mt-2 flex justify-between">
              <div className="flex flex-row justify-start space-x-2 w-1/2">
                <span>{formatZil(account.balance)}</span>
              </div>
              <div>{account.path}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccountSlider: React.FC<AccountSliderInterface> = ({
  accounts,
  children,
  onChange,
}) => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index: number) => {
    setIndex(index);
    if (onChange) {
      onChange(index);
    }
  };

  const renderIndex = (params: any) => {
    const { index, key } = params;
    return <SlideRenderer index={index} key={key} accounts={accounts} />;
  };

  return (
    <div className="flex flex-col w-full py-4">
      <div className="flex-1">
        <VirtualizeSwipeableViews
          enableMouseEvents
          index={index}
          onChangeIndex={handleChangeIndex}
          slideRenderer={renderIndex}
        />
      </div>

      <div className="flex justify-between px-6 py-2">
        <button
          className="hover:bg-gray-200 text-gray-300 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
          onClick={() => handleChangeIndex(index - 1)}
        >
          <FaAngleLeft />
        </button>
        {children}
        <button
          className="hover:bg-gray-200 text-gray-300 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
          onClick={() => handleChangeIndex(index + 1)}
        >
          <FaAngleRight />
        </button>
      </div>
    </div>
  );
};

export default AccountSlider;
