import * as bip39 from "bip39";
import hdkey from "hdkey";
import * as elliptic from "elliptic";
import * as bs58check from "bs58check";
import * as crypto from "crypto-browserify";
import { Account } from "./Account";
import { BN } from "bn.js";
import hashjs from "hash.js";
import { toChecksumAddress, toBech32 } from "./Utils";

// import { randomBytes, createHash } from "crypto";
export const NUMBER_OF_WORDS = 24;

const ec = new elliptic.ec("secp256k1");

// Derive the Zilliqa Ledger Live path for a given account index
export const getZilliqaLedgerLivePath = (index: number): string => {
  return `m/44'/313'/${index}'/0'/0'`;
};

// Derive the Zilliqa private key for a given account index and mnemonic
export const deriveZilliqaPrivateKey = (
  path: string,
  mnemonic: string
): string => {
  const seed = bip39.mnemonicToSeedSync(mnemonic);
  const masterNode = hdkey.fromMasterSeed(seed);

  const childKey = masterNode.derive(path);
  const privateKey = childKey.privateKey.toString("hex");

  return privateKey;
};

// Encrypt the private key with a password
// Encrypt the private key with a password
export const encryptMessage = (message: string, password: string): string => {
  const salt = crypto.randomBytes(16);
  const key = crypto.pbkdf2Sync(password, salt, 100000, 32, "sha256");
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
  const encrypted = Buffer.concat([
    cipher.update(message, "utf8"),
    cipher.final(),
  ]);
  const result = Buffer.concat([salt, iv, encrypted]);
  return result.toString("hex");
};

// Decrypt the private key with a password
export const decryptMessage = (message: string, password: string): string => {
  const buffer = Buffer.from(message, "hex");
  const salt = buffer.slice(0, 16);
  const iv = buffer.slice(16, 32);
  const encrypted = buffer.slice(32);
  const key = crypto.pbkdf2Sync(password, salt, 100000, 32, "sha256");
  const decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
  const decrypted = Buffer.concat([
    decipher.update(encrypted),
    decipher.final(),
  ]);
  return decrypted.toString("utf8");
};

// Get the Zilliqa address for a given account index and mnemonic
export const getZilliqaAccount = (
  index: number,
  mnemonic: string,
  password: string
): Account => {
  const path = getZilliqaLedgerLivePath(index);

  const privateKey = deriveZilliqaPrivateKey(path, mnemonic);
  const encryptedPrivateKey = encryptMessage(privateKey, password);

  // TODO: Normalise private key?
  const publicKey = ec.keyFromPrivate(privateKey).getPublic(true, "hex");
  const addressNoChecksum =
    "0x" + hashjs.sha256().update(publicKey, "hex").digest("hex").slice(24);
  const address = toChecksumAddress(addressNoChecksum);
  const bech32Address = toBech32(addressNoChecksum);
  return {
    index,
    accountName: "Account " + index,
    encryptedPrivateKey,
    publicKey: "0x" + publicKey,
    address,
    bech32Address,
    path,
  };
};

export const getRandomPassword = (): string => {
  const entropy = crypto.randomBytes(32);
  return entropy.toString("utf8");
};

// Generate a Ledger Live-compatible BIP39 mnemonic phrase
export const generateMnemonic = (): string => {
  const entropy = crypto.randomBytes((16 * NUMBER_OF_WORDS) / 12);
  const wordlist = bip39.wordlists.english; // Use the BIP39 English wordlist
  const mnemonic = bip39.entropyToMnemonic(entropy, wordlist);
  return mnemonic;
};

// Get the public key for a given private key
export const getPublicKey = (privateKey: string): string => {
  const key = ec.keyFromPrivate(privateKey);
  const publicKey = key.getPublic("hex");
  return publicKey;
};
