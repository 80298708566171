import React from "react";
import { TransactionSummary } from "../api/Indexer";
import BN from "bn.js";
import Loading from "./Loading";
import { BiTimeFive } from "react-icons/bi";
import { formatZil } from "../account/Utils";

interface TransactionListProps {
  transactions: TransactionSummary[];
  updating?: boolean;
}

function humanizeDate(date: Date): string {
  const now = new Date();
  const diff = Math.round((now.getTime() - date.getTime()) / 1000);
  return date.toDateString();
  if (diff < 60) {
    return "just now";
  } else if (diff < 60 * 60) {
    const minutes = Math.round(diff / 60);
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (diff < 60 * 60 * 24) {
    const hours = Math.round(diff / (60 * 60));
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (diff < 60 * 60 * 24 * 7) {
    const days = Math.round(diff / (60 * 60 * 24));
    return `${days} day${days === 1 ? "" : "s"} ago`;
  } else {
    return date.toDateString();
  }
}

const TransactionList: React.FC<TransactionListProps> = ({
  transactions,
  updating,
}) => {
  return (
    <div className="border-t border-gray-200 divide-y divide-gray-200 w-full h-full max-h-full mb-4 overflow-y-auto">
      {updating ? (
        <Loading text="Transactions loading." />
      ) : transactions.length === 0 ? (
        <div className="border-t border-gray-200 divide-y divide-gray-200 w-full h-full max-h-full mb-4 overflow-y-auto flex flex-col text-center align-center justify-center">
          <span>No transactions yet.</span>
        </div>
      ) : (
        transactions.map((transaction) => (
          <div key={transaction.id} className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              <div className="text-sm leading-5 font-medium text-gray-900 max-w-1 overflow-hidden truncate">
                {transaction.description
                  ? transaction.description
                  : transaction.direction === "in"
                  ? transaction.fromAddress
                  : transaction.toAddress}
              </div>
              <div className="ml-2 flex-shrink-0 flex">
                <span
                  className={
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full " +
                    (transaction.direction === "in"
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800")
                  }
                >
                  {transaction.direction === "in" ? "+" : "-"}
                  {formatZil(transaction.amount.abs())}
                </span>
              </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <div className="flex flex-row justify-start items-center text-sm  text-gray-500 space-x-1">
                  <BiTimeFive />
                  <div>{humanizeDate(transaction.timestamp)}</div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default TransactionList;
