import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import ChatComponent, { ChatStateType } from "./chat";
import MobileHeader from "./MobileHeader";
import MobileFooter from "./MobileFooter";
import { useHistory, Switch, Route, Link } from "react-router-dom";
import AccountSetup from "./accountSetup";
import PasswordDialog from "./login/PasswordDialog";
import AccountSlider from "./components/AccountSlider";
import TransactionList from "./components/TransactionList";
import ReceiveModal from "./components/ReceiveModal";
import Container from "./components/Container";
import { ZilliqaGraphQLApiClient, TransactionSummary } from "./api/Indexer";
import {
  Account,
  deserializeAccounts,
  serializeAccounts,
} from "./account/Account";
import {
  MdOutlineSendToMobile,
  MdQrCodeScanner,
  MdAddCard,
} from "react-icons/md";
import {
  STORAGE_SEED_PHRASE,
  STORAGE_ENCRYPTION_KEY,
  STORAGE_ACCOUNTS,
  STORAGE_OPENAI_API_KEY,
  STORAGE_ASSISTANT_MESSAGES,
} from "./account/Storage";
import BN from "bn.js";
import { decryptMessage, encryptMessage } from "./account/KeyManagement";
import { ApiKeyForm } from "./chat/ApiKey";

type OperatingMode = "normal" | "setup" | "setupAccount";

type WalletState = "home" | "receiveTokens";
interface WalletProps {
  accounts: Account[];
}

const Wallet: React.FC<WalletProps> = ({ accounts }) => {
  // Local state
  const [state, setState] = useState<WalletState>("home");
  const [updateTxListInProgress, setUpdateTxListInProgress] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [transactions, setTransactions] = useState<TransactionSummary[]>([]);

  const updateTransactionList = () => {
    if (accounts.length === 0) {
      return;
    }
    const client = new ZilliqaGraphQLApiClient();
    client
      .getTransactions({
        addr: accounts[selectedAccount % accounts.length].address,
      })
      .then((data) => {
        data.sort((a, b) => {
          return a.timestamp < b.timestamp
            ? 1
            : a.timestamp > b.timestamp
            ? -1
            : 0;
        });
        setTransactions(data);
        setUpdateTxListInProgress(false);
      })
      .catch((error) => {
        console.error(error);
        setUpdateTxListInProgress(false);
      });
  };

  useEffect(() => {
    setUpdateTxListInProgress(true);
    updateTransactionList();
  }, [selectedAccount, accounts]);

  let bechAddress = "";
  let address = "";
  if (accounts.length > 0) {
    bechAddress = accounts[selectedAccount % accounts.length].bech32Address;
    address = accounts[selectedAccount % accounts.length].address;
  }

  return (
    <div className="flex flex-col w-full h-full flex-1 overflow-hidden mb-4;">
      <AccountSlider accounts={accounts} onChange={setSelectedAccount}>
        <button
          onClick={() => {
            alert("Yet to be implemented.");
          }}
          className="hover:bg-gray-200 hover:text-gray-600 text-gray-300 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
        >
          <MdOutlineSendToMobile />
        </button>
        <button
          onClick={() => setState("receiveTokens")}
          className="hover:bg-gray-200 hover:text-gray-600 text-gray-300 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
        >
          <MdQrCodeScanner />
        </button>
        <button
          onClick={() => {
            alert("Yet to be implemented.");
          }}
          className="hover:bg-gray-200 hover:text-gray-600 text-gray-300 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
        >
          <MdAddCard />
        </button>
      </AccountSlider>
      <TransactionList
        transactions={transactions}
        updating={updateTxListInProgress}
      />
      <ReceiveModal
        isOpen={state == "receiveTokens"}
        bechAddress={bechAddress}
        address={address}
        onClose={() => setState("home")}
      />
    </div>
  );
};

interface SettingsProps {
  setAccounts: (x: Account[]) => void;
  setEncryptionKey: (x: string) => void;
  setEncryptedEncryptionKey: (x: string) => void;
}

const Settings: React.FC<SettingsProps> = ({
  setAccounts,
  setEncryptionKey,
  setEncryptedEncryptionKey,
}) => {
  const onDelete = () => {
    if (localStorage.getItem(STORAGE_SEED_PHRASE)) {
      localStorage.removeItem(STORAGE_SEED_PHRASE);
    }

    if (localStorage.getItem(STORAGE_ENCRYPTION_KEY)) {
      localStorage.removeItem(STORAGE_ENCRYPTION_KEY);
    }

    if (localStorage.getItem(STORAGE_ACCOUNTS)) {
      localStorage.removeItem(STORAGE_ACCOUNTS);
    }

    if (localStorage.getItem(STORAGE_OPENAI_API_KEY)) {
      localStorage.removeItem(STORAGE_OPENAI_API_KEY);
    }

    if (localStorage.getItem(STORAGE_ASSISTANT_MESSAGES)) {
      localStorage.removeItem(STORAGE_ASSISTANT_MESSAGES);
    }

    setAccounts([]);
    setEncryptionKey("");
    setEncryptedEncryptionKey("");
  };

  return (
    <div className="flex flex-col items-stretch space-y-2 w-full">
      <ApiKeyForm />
      <button
        onClick={onDelete}
        className="bg-red-500 hover:bg-teal-600 text-white py-2 px-6 rounded focus:outline-none w-full"
      >
        Delete wallet
      </button>
    </div>
  );
};

function MainApp() {
  const [operatingMode, setOperatingMode] = useState<OperatingMode>("normal");

  // Global state
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [encryptionKey, setEncryptionKey] = useState("");
  const [encryptedEncryptionKey, setEncryptedEncryptionKey] = useState("");

  const [isLocked, setIsLocked] = useState(false);
  const [isWaitingRepsonse, setIsWaitingResponse] = useState(false);

  let history = useHistory();
  const setUrl = (u: string) => {
    history.push(u);
  };

  const stateChange = (s: ChatStateType) => {
    setIsWaitingResponse(s === "waiting");
  };

  useEffect(() => {
    const encryptedKey = localStorage.getItem(STORAGE_ENCRYPTION_KEY);
    if (encryptedKey && encryptedKey !== "") {
      setEncryptedEncryptionKey(encryptedKey);
    }

    const accounts_payload = localStorage.getItem(STORAGE_ACCOUNTS);
    if (accounts_payload) {
      try {
        const accounts = deserializeAccounts(accounts_payload);

        if (accounts) {
          setAccounts(accounts);
        }
      } catch (error) {}
    }
  }, []);

  const setAndStoreEncryptionKey = (
    password: string,
    masterPassword: string
  ) => {
    // Ensuring that we are keep operating setting up the account even when
    // we have set the encryptedEncryptionKey
    setOperatingMode("setupAccount");

    const encryptedPassword = encryptMessage(`key:${password}`, masterPassword);
    localStorage.setItem(STORAGE_ENCRYPTION_KEY, `${encryptedPassword}`);

    setEncryptionKey(password);
    setEncryptedEncryptionKey(encryptedPassword);
  };

  const unlock = (password: string) => {
    try {
      const encryptionKey = decryptMessage(encryptedEncryptionKey, password);
      if (encryptionKey.startsWith("key:")) {
        setEncryptionKey(encryptionKey.substring(4));
        return true;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
    return false;
  };

  //  setState("setupAccount")
  if (encryptedEncryptionKey === "" || operatingMode === "setupAccount") {
    const onDone = (accounts: Account[]) => {
      const account_payload = serializeAccounts(accounts);
      localStorage.setItem(STORAGE_ACCOUNTS, account_payload);
      setAccounts(accounts);
      setOperatingMode("normal");
      setUrl("/");
    };
    if (accounts.length === 0) {
      return (
        <AccountSetup
          onDone={onDone}
          setEncryptionKey={setAndStoreEncryptionKey}
        />
      );
    } else {
      return (
        <AccountSetup
          onCancel={() => setOperatingMode("normal")}
          onDone={onDone}
        />
      );
    }
  }

  if (encryptionKey === "") {
    return <PasswordDialog onUnlock={unlock} />;
  }

  return (
    <Container>
      <MobileHeader title="Wallet" key="header" />

      <Switch>
        <Route exact path="/">
          <Wallet accounts={accounts} />
        </Route>
        <Route path="/assistant">
          <ChatComponent onChangeState={stateChange} />
        </Route>
        <Route path="/settings">
          <Settings
            setAccounts={setAccounts}
            setEncryptionKey={setEncryptionKey}
            setEncryptedEncryptionKey={setEncryptedEncryptionKey}
          />
        </Route>
      </Switch>
      <MobileFooter
        workInProgress={isWaitingRepsonse}
        onLeftButtonClick={() => setUrl("/")}
        onMiddleButtonClick={() => setUrl("/assistant")}
        onRightButtonClick={() => setUrl("/settings")}
      />
    </Container>
  );
}

function App() {
  return (
    <div className="flex items-center justify-center h-screen max-h-full">
      <MainApp />
    </div>
  );
}

export default App;
