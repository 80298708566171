import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

interface MobileHeaderProps {
  title?: string;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ title }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="h-20 w-full border-b flex flex-col items-center justify-center">
        <header className="flex flex-row justify-between items-center py-2 w-full">
          <div className="px-2"></div>
          <h1 className="text-lg font-bold">{title}</h1>
          <div className="px-2">
            <button
              className={isMenuOpen ? "invisible" : "focus:outline-none"}
              onClick={toggleMenu}
            >
              <FaBars />
            </button>
          </div>
        </header>
      </div>
      {isMenuOpen && (
        <nav className="text-white bg-teal-600 px-4 py-2 z-10 fixed top-0 left-0 bottom-0 w-80 ">
          <div className="flex flex-row justify-end items-center py-2 w-full">
            <button className="focus:outline-none" onClick={toggleMenu}>
              <FaTimes />
            </button>
          </div>
          <ul>
            <li className="mb-1">
              <a href="https://zilliqa.com" className="hover:text-teal-300">
                Zilliqa
              </a>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default MobileHeader;
