import React, { useState } from "react";

export interface Image {
  src: string;
  alt: string;
}

export interface Props {
  images: Image[];
}

const ImageCollection: React.FC<Props> = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const handleImageClick = (image: Image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          className="cursor-pointer transform hover:scale-110 transition-all duration-300"
          onClick={() => handleImageClick(image)}
        />
      ))}
      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="relative">
            <img src={selectedImage.src} alt={selectedImage.alt} />
            <button
              className="absolute top-0 right-0 m-4 text-white text-2xl hover:text-gray-300 focus:outline-none"
              onClick={handleClose}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCollection;
