import React, { useState } from "react";
import QRCode from "qrcode.react";
import Container from "./Container";
interface Props {
  address: string;
  bechAddress: string;
  isOpen: boolean;
  onClose: () => void;
}

const ReceiveModal: React.FC<Props> = ({
  address,
  bechAddress,
  isOpen,
  onClose,
}) => {
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);

  const handleCopyClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    const input = event.currentTarget.previousSibling as HTMLInputElement;
    input.select();
    document.execCommand("copy");
    setCopied1(true);
    setCopied2(false);
  };

  const handleCopyClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    const input = event.currentTarget.previousSibling as HTMLInputElement;
    input.select();
    document.execCommand("copy");
    setCopied1(false);
    setCopied2(true);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 z-10 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <Container justify="center" border={false}>
            <div className="bg-white rounded-lg shadow-md p-8 w-full">
              <div className="mb-4 w-full flex justify-center align-center">
                <QRCode value={address} />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="receive-address"
                >
                  Base 16 address
                </label>
                <div className="relative">
                  <input
                    readOnly
                    className="appearance-none border rounded-md w-full py-2 pl-3 pr-16 truncate text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="receive-address"
                    type="text"
                    value={address}
                  />
                  <button
                    onClick={handleCopyClick1}
                    className="absolute right-0 top-0 bottom-0 px-3 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                  >
                    {copied1 ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="receive-label"
                >
                  ZIL address
                </label>
                <div className="relative">
                  <input
                    readOnly
                    className="appearance-none border rounded-md w-full py-2 pl-3 pr-16 truncate text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="receive-label"
                    type="text"
                    placeholder="Optional label for the address"
                    value={bechAddress}
                  />
                  <button
                    onClick={handleCopyClick2}
                    className="absolute right-0 top-0 bottom-0 px-3 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                  >
                    {copied2 ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
              <button
                onClick={onClose}
                className="bg-blue-500 hover:bg-blue-700 text-white text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default ReceiveModal;
