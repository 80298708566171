import React from "react";

interface ContainerProps {
  children: React.ReactNode;
  justify?: "between" | "start" | "end" | "center";
  border?: boolean;
}

const Container: React.FC<ContainerProps> = ({ children, justify, border }) => {
  let extraClasses = "justify-between";
  if (justify) {
    extraClasses = "justify-" + justify;
  }
  if (border !== false) {
    extraClasses += " border-l border-r";
  }
  return (
    <div
      className={
        "flex flex-col items-center h-screen px-2 w-96 max-h-full " +
        extraClasses
      }
    >
      {children}
    </div>
  );
};

export default Container;
