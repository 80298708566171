import React, { useState } from "react";
import Container from "../components/Container";
import Overlay from "../components/Overlay";
import RecoveryCard from "./RecoveryCard";

export interface Word {
  id: number;
  value: string;
}

interface WordListViewImplProps {
  words: Word[];
}

interface WordListViewProps {
  words: Word[];
  onPrev?: () => void;
  onNext?: () => void;
}

const WordListViewImpl: React.FC<WordListViewImplProps> = ({ words }) => {
  const [selectedWords, setSelectedWords] = useState<Word[]>([]);

  return (
    <RecoveryCard>
      <ul className="flex flex-col gap-2 flex-grow overflow-y-auto">
        {words.map((word, index) => (
          <li
            key={word.id}
            className="flex justify-between items-center px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200"
          >
            <span>
              {index + 1}. {word.value}
            </span>
          </li>
        ))}
      </ul>
    </RecoveryCard>
  );
  return (
    <div className="flex flex-col w-full  flex-1 h-full overflow-hidden">
      <div className="flex flex-col w-full bg-gray-100 rounded-md shadow-lg p-4 mb-4 h-screen max-h-full">
        <h2 className="text-lg font-bold mb-2">Recovery card</h2>
        <ul className="flex flex-col gap-2 flex-grow overflow-y-auto">
          {words.map((word, index) => (
            <li
              key={word.id}
              className="flex justify-between items-center px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200"
            >
              <span>
                {index + 1}. {word.value}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const WordListShow: React.FC<WordListViewProps> = ({
  words,
  onPrev,
  onNext,
}) => {
  return (
    <Overlay>
      <Container justify="center">
        <p className="text-center">
          Please write these words down in order. This is your recovery phrase
          in case you loose access to your wallet.
        </p>
        <WordListViewImpl words={words} />
        <div className="flex flex-row w-full space-x-2">
          <button
            onClick={onPrev}
            type="submit"
            className="flex-grow border border-teal-500 hover:bg-gray-300 text-teal-500 text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
          >
            Back
          </button>
          <button
            onClick={onNext}
            type="submit"
            className="flex-grow bg-teal-500 hover:bg-teal-700 text-white text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
          >
            Next
          </button>
        </div>
      </Container>
    </Overlay>
  );
};

export default WordListShow;
