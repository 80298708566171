import React, { useState } from "react";
import logo from "../logo.svg";

interface Props {
  onGenerateNewAccount?: (name: string) => void;
  onImportAccount?: (name: string) => void;
  onCancel?: () => void;
}

const AccountSetupFront: React.FC<Props> = ({
  onGenerateNewAccount,
  onImportAccount,
  onCancel,
}) => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const handlePassword1Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword1(event.target.value);
  };

  const handlePassword2Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword2(event.target.value);
  };

  const handleGenerateNewAccount = () => {
    if (password1.trim() === "" || password1 !== password2) {
      // Account name is required
      return;
    }
    onGenerateNewAccount && onGenerateNewAccount(password1.trim());
  };

  const handleImportAccount = () => {
    if (password1.trim() === "") {
      // Account name is required
      return;
    }
    onImportAccount && onImportAccount(password1.trim());
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <>
      <div className="w-1/2 flex justify-between items-center">
        <img src={logo} alt="logo" />
      </div>
      <div className="flex flex-col items-center mt-16">
        <h2 className="text-2xl font-bold mb-8">Account Setup</h2>
        <div className="flex flex-col justify-center items-stretch space-y-2">
          <input
            className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="account-name"
            type="password"
            placeholder="Password"
            value={password1}
            onChange={handlePassword1Change}
          />
          <input
            className="mb-8 appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="account-name"
            type="password"
            placeholder="Repeat password"
            value={password2}
            onChange={handlePassword2Change}
          />

          <button
            className="bg-teal-500 hover:bg-teal-700 text-white text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
            type="button"
            onClick={handleGenerateNewAccount}
          >
            Generate New Account
          </button>
          <button
            className="bg-teal-500 hover:bg-teal-700 text-white text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
            type="button"
            onClick={handleImportAccount}
          >
            Import Account
          </button>
          {onCancel && (
            <button
              className="mt-4 border border-teal-500 hover:bg-gray-300 text-teal-500 text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountSetupFront;
