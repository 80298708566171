import React, { useState, useEffect } from "react";
import Container from "../components/Container";
import Overlay from "../components/Overlay";
import { Word } from "./WordListShow";
import { Account } from "../account/Account";
import RecoveryCard from "./RecoveryCard";

interface WordListVerifyImplProps {
  originalWords: Word[];
  setValidation: (b: boolean) => void;
}

interface WordListVerifyProps {
  words: Word[];
  onPrev: () => void;
  onNext: () => void;
}

const WordVerifyImpl: React.FC<WordListVerifyImplProps> = ({
  originalWords,
  setValidation,
}) => {
  const [words, setWords] = useState<Word[]>([]);
  const [selectedWords, setSelectedWords] = useState<Word[]>([]);
  useEffect(() => {
    // setWords(originalWords);
    // setSelectedWords([]);

    // TODO: currently in cheat mode
    setWords([]);
    originalWords.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
    setSelectedWords(originalWords);
    setValidation(true);
  }, [originalWords]);

  useEffect(() => {
    if (selectedWords.length === originalWords.length) {
      let valid = true;
      for (let i = 0; i < selectedWords.length; ++i) {
        valid = valid && selectedWords[i].id === i;
      }
      setValidation(valid);
    } else {
      setValidation(false);
    }
  }, [selectedWords]);
  const moveWord = (word: Word) => {
    setWords((prevWords) => prevWords.filter((w) => w.id !== word.id));
    setSelectedWords((prevSelectedWords) => [...prevSelectedWords, word]);
  };

  const removeWord = (word: Word) => {
    setSelectedWords((prevSelectedWords) =>
      prevSelectedWords.filter((w) => w.id !== word.id)
    );
    setWords((prevWords) => [...prevWords, word]);
  };

  return (
    <RecoveryCard>
      <ul className="flex flex-col gap-2 flex-1 overflow-y-auto">
        {selectedWords.map((word, index) => (
          <li
            key={word.id}
            className="flex justify-between items-center px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200"
          >
            <span>
              {index + 1}. {word.value}
            </span>
            <svg
              onClick={() => removeWord(word)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500 hover:text-gray-700"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.293-9.707a1 1 0 00-1.414-1.414L10 8.586l-1.879-1.879a1 1 0 00-1.414 1.414L8.586 10l-1.879 1.879a1 1 0 001.414 1.414L10 11.414l1.879 1.879a1 1 0 001.414-1.414L11.414 10l1.879-1.879z"
                clipRule="evenodd"
              />
            </svg>
          </li>
        ))}
      </ul>
      <ul
        className={
          "flex flex-col gap-2" +
          (words.length > 0 ? " border-t mt-2 " : " mt-2")
        }
      >
        {words.map((word) => (
          <li
            key={word.id}
            className="flex justify-between items-center px-2 py-1 rounded-md cursor-pointer hover:bg-gray-200"
            onClick={() => moveWord(word)}
          >
            <span>{word.value}</span>
          </li>
        ))}
      </ul>
    </RecoveryCard>
  );
};

const WordVerify: React.FC<WordListVerifyProps> = ({
  words,
  onPrev,
  onNext,
}) => {
  const [canContinue, setCanContinue] = useState(false);
  return (
    <Overlay>
      <Container justify="center">
        <p className="text-center">
          Please order the words with the same order as the had on the previous
          screen. Click on the first word, then the second etc.
        </p>
        <WordVerifyImpl originalWords={words} setValidation={setCanContinue} />
        <div className="flex flex-row w-full space-x-2">
          <button
            onClick={onPrev}
            type="submit"
            className="flex-grow border border-teal-500 hover:bg-gray-300 text-teal-500 text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
          >
            Back
          </button>
          <button
            disabled={!canContinue}
            onClick={() => canContinue && onNext()}
            type="submit"
            className={
              (canContinue
                ? "bg-teal-500 hover:bg-teal-700 text-white"
                : "bg-gray-300 text-white ") +
              " flex-grow text-center font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline w-full"
            }
          >
            Next
          </button>
        </div>
      </Container>
    </Overlay>
  );
};

export default WordVerify;
