import React, { useState, useEffect } from "react";
import { AddressBook, ZilliqaTransaction } from "../types";
import { formatZil } from "../account/Utils";

interface TransactionWidgetProps {
  transaction: ZilliqaTransaction;
  addressBook: AddressBook;
  onSend?: () => void;
  onCancel?: () => void;
}

function makeReverseAddressBook(obj: AddressBook): AddressBook {
  const reversedObj: AddressBook = {};

  for (const [key, value] of Object.entries(obj)) {
    reversedObj[value] = key;
  }

  return reversedObj;
}

const TransactionWidget: React.FC<TransactionWidgetProps> = ({
  transaction,
  addressBook,
  onSend,
  onCancel,
}) => {
  const [message, setMessage] = useState("");
  const [reverseAddressBook, setReverseAddressBook] = useState<AddressBook>({});

  useEffect(() => {
    setReverseAddressBook(makeReverseAddressBook(addressBook));
  }, [addressBook]);

  const handleSend = () => {
    // Handle sending the transaction
    onSend && onSend();
  };

  const handleCancel = () => {
    // Handle cancelling the transaction
    onCancel && onCancel();
  };

  const toName = reverseAddressBook[transaction.toAddr] || transaction.toAddr;

  return (
    <div className="w-64 bg-white rounded-lg overflow-hidden my-4">
      <div className="p-4 bg-gray-200 text-gray-700 font-bold">
        Transaction Summary
      </div>
      <div className="p-4">
        <div className="flex flex-row items-center mb-2">
          <div className="w-1/3 text-gray-500 font-medium">To:</div>
          <div className="w-2/3 truncated">{toName}</div>
        </div>
        <div className="flex flex-row items-center mb-2">
          <div className="w-1/3 text-gray-500 font-medium">Amount:</div>
          <div className="w-2/3">{formatZil(transaction.amount)}</div>
        </div>
        <div className="flex flex-row justify-end mt-4 space-x-2">
          <button
            className="border border-red-500 hover:bg-red-700 text-red-500 hover:text-white font-bold py-2 px-4 rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleSend}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionWidget;
